@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Montserrat:wght@600;700&family=Nunito:wght@400;800&display=swap');

.App {
  text-align: center;
  user-select: none;
  cursor: default;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: #1A1A1A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 25px;
}

.App-termo p{
  max-width: 100%;
}

.App-link {
  color: #61dafb;
}

.button {
  padding: 15px 15px;
  color: white;
  background-color: #000;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Nunito';
  transition: background-color 0.2s;
}

.button-small {
  padding: 15px 30px;
  color: white;
  background-color: #000;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Nunito';
  transition: background-color 0.2s;
  font-size: 2vmin;
}

.button:hover{
  background-color: rgb(16, 16, 16);
}

.button:active{
  background-color: rgb(20, 20, 20);
}

.button-small:hover{
  background-color: rgb(16, 16, 16);
}

.button-small:active{
  background-color: rgb(20, 20, 20);
}

p{
  max-width: 700px;
  font-family: 'Nunito';
}

h1{
  font-family: 'Montserrat';
  font-size: 5vmin;
  margin: 0
}

footer{
  width: 100vw;
  height: 70px;
  display: flex;
  justify-content: center;
  background-color: #000;
  margin-top: 50px;
}

footer img{
  width: 100px;
}

i{
  font-size: 2vmin;
  cursor: pointer;
}

i:hover{
  text-decoration: underline;
}

/* input{
  width: 200px;
  height: 50px;
  border-radius: 12px;
  border-width: 1px;
  background-color: #00000000;
  box-shadow: none;
  user-select: none;
  border-style: solid;
  border-color: white;
  color: white;
} */

input{
  width: 200px;
  height: 25px;
  margin: 10px 0;
}

.leitura-termo{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */
}

.leitura-termo h1{
  font-family: 'Lora';
  font-size: 4vmin;
  text-decoration: underline;
}
.leitura-termo h2{
  font-family: 'Lora';
  font-size: 3vmin;
  text-decoration: underline;
  max-width: 60%;
}

.leitura-termo p{
  max-width: 60%;
  /* justify-self: center; */
  font-family: 'Lora';
  text-align: justify;
  font-size: 2.4vmin;
  line-height: 3.7vmin;
}



.leitura-termo img{
  margin-bottom: 20px;
}

.aceite{
  width: 60%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 42px;
}

.aceite text{
  font-family: 'Lora';
  font-size: 2.4vmin;
  align-self: flex-start;
}

.formulario{
  width: 50%;
}

.formulario p{
  /* justify-self: center; */
  max-width: 50%;
  font-family: 'Lora';
  font-size: 2.4vmin;
  line-height: 3.7vmin;
  text-align: left;
}

.formulario-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}